import menuAim from './menu-aim.js';
import { getWindowWidth, getOffset, closestWithClass } from "./utils.js";
import Collapse from "bootstrap/js/src/collapse";

menuAim(document.getElementById('mainmenu'), {
  activate: function(li) {li.classList.add("hover");},
  deactivate: function(li) {li.classList.remove("hover");},
  activateMenu: function(ul) {ul.classList.add("hover");},
  exitMenu: function(ul) {ul.classList.remove("hover"); return true;},
  submenuDirection: "below"
});

function mainmenuScrollCheck() { //this is array of [element, scrollBody, endTs]
  var linkTop = getOffset(this[0]).top - getOffset(this[1]).top;
  if(linkTop < 0) this[1].scrollTop += linkTop;
  if(this[2] > new Date().getTime()) setTimeout(mainmenuScrollCheck.bind(this), 1000/60);
}
function mainmenuClick(e) {
  var windowWidth = getWindowWidth();
  if(windowWidth < 992) {
    var submenu = this.parentNode.querySelector(".mainsubmenu");
    if(!submenu) return;
    e.preventDefault();
    var collapse = Collapse.getOrCreateInstance(submenu, { parent: '#mainmenu', toggle: false });
    collapse.toggle();
    setTimeout(mainmenuScrollCheck.bind([this, closestWithClass(this, "offcanvas-body"), (new Date().getTime())+450/* bs animation is 350ms */]), 1000/60);
  }
}
document.querySelectorAll('#mainmenu > li > a').forEach(function(link) {
  link.addEventListener('click', mainmenuClick);
});
