import Rails from "@rails/ujs";
import { closestWithClass } from "./utils.js";

function placemap_zoom(button, dir) {
  var placemap = closestWithClass(button, "placemap");
  var img = placemap.querySelector("img");
  var zoom = parseInt(img.getAttribute("data-zoom"));
  zoom += dir;
  if(zoom < 6) zoom = 6;
  if(zoom > 20) zoom = 20;
  img.setAttribute("data-zoom", zoom);
  img.src = img.src.replace(/map\-\d+/, "map-"+zoom);
  var buttons = placemap.querySelectorAll(".placemap_zoom_button");
  zoom == 6 ? buttons[0].classList.add("disabled") : buttons[0].classList.remove("disabled");
  zoom == 20 ? buttons[1].classList.add("disabled") : buttons[1].classList.remove("disabled");
}

Rails.delegate(document, ".placemap_zoom_button", 'click', function(e) {
  placemap_zoom(this, this.getAttribute("data-zoom") == "+" ? 1 : -1);
});
