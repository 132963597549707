import Rails from "@rails/ujs";
import Collapse from "bootstrap/js/src/collapse";
import { closestWithClass } from "./utils.js";

var SPINNER_HTML = '<i class="icon icon-spinner"></i>';

// cart items and shipping and payment methods
var cart_change_timeout = null;
var cart_change_queue = [];
var cart_queue_processing = false;
function cart_queue_process() {
  if(cart_queue_processing) return;
  if(cart_change_queue.length == 0) return;
  cart_queue_processing = true;
  var callback = cart_change_queue.shift();
  callback();
  setTimeout(function() {
    callback(); // callback must be called for sure before sending ajax.. other calls are just to give a user some feedback
    var form = document.getElementById("cart-form");
    Rails.ajax({
      type: "POST",
      url: form.getAttribute('action'),
      data: Rails.serializeElement(form),
      dataType: 'script',
      complete: function(xhr, statusText) {
        send_cart_finish();
        cart_queue_processing = false;
        cart_queue_process();
      }
    });
  }, 150);
}
function send_cart_form(ms, callback) {
  callback();
  if(!ms) ms = 500;
  if(cart_change_timeout) {
    clearTimeout(cart_change_timeout);
    cart_change_timeout = null;
  }
  cart_change_timeout = setTimeout(function() {
    if(cart_change_queue.length > 50) return; //emergency stop
    cart_change_queue.push(callback);
    cart_queue_process();
  }, ms);
}
function send_cart_finish() {
  document.querySelectorAll(".cart-item").forEach(function(item) {
    var sum_el = item.querySelector(".price_sum_product");
    if(sum_el && sum_el.querySelector(".icon")) sum_el.innerHTML = "0 Kč"; //removed from cart
  });
}
function send_cart_form_product(element, ms) {
  var cb = function() {
    document.getElementById("cart_action_source").value = "count";
    closestWithClass(element, "cart-item").querySelector(".price_sum_product").innerHTML = SPINNER_HTML;
    document.getElementById("new_shipping_info").innerHTML = SPINNER_HTML;
    document.getElementById("new_shipping_discount").innerHTML = SPINNER_HTML;
    document.querySelectorAll("#new_shipping_methods .variable, #new_payment_methods .variable, #new_total .variable").forEach(function(el) { el.innerHTML = SPINNER_HTML });
  };
  send_cart_form(ms, cb);
}
function discount_code_changed() {
  var cb = function() {
    document.getElementById("cart_action_source").value = "discount_code";
    var signalizer = document.querySelector("#new_discount_code .signalizer");
    if(signalizer) {
      signalizer.style.width = signalizer.offsetWidth + "px";
      signalizer.innerHTML = SPINNER_HTML;
    }
  };
  send_cart_form(1, cb);
}
function shipping_method_changed() {
  var cb = function() {
    document.getElementById("cart_action_source").value = "shipping";
    document.querySelectorAll("#new_payment_methods .variable, #new_total .variable").forEach(function(el) { el.innerHTML = SPINNER_HTML });
  };
  send_cart_form(1, cb);
}
function payment_method_changed() {
  var cb = function() {
    document.getElementById("cart_action_source").value = "payment";
    document.querySelectorAll("#new_total .variable").forEach(function(el) { el.innerHTML = SPINNER_HTML });
  };
  send_cart_form(1, cb);
}

var cart_form = document.getElementById("cart-form");
if(cart_form) {
  // product count
  cart_form.querySelectorAll("input.product_count").forEach(function(input) {
    input.addEventListener("change", function(e) {
      if(!this.value.match(/^\d+$/i)) this.value = 0;
      send_cart_form_product(this);
    });
    input.addEventListener("keyup", function(event) {
      if(event.keyCode == 8 || event.keyCode > 32 && event.key.match(/^\d+$/i) && this.value.match(/^\d+$/i)) send_cart_form_product(this, 1500);
    });
    input.addEventListener("keypress", function(event) {
      if(event.keyCode == 13) {
        send_cart_form_product(this, 1500);
        event.preventDefault();
      }
    });
    input.parentNode.querySelector("button.btn-danger").addEventListener("click", function(e) {
      var val = parseInt(input.value);
      if(isNaN(val)) val = 2
      input.value = val - 1;
      send_cart_form_product(input);
    });
    input.parentNode.querySelector("button.btn-success").addEventListener("click", function(e) {
      var val = parseInt(input.value);
      if(isNaN(val)) val = 1
      input.value = val + 1;
      send_cart_form_product(input);
    });
  });
  // product remove
  cart_form.querySelectorAll('.cart_remove').forEach(function(btn) {
    btn.addEventListener("click", function(e) {
      var input = closestWithClass(btn, "cart-item").querySelector(".product_count");
      input.value = 0;
      send_cart_form_product(input);
      closestWithClass(btn, "cart-item").classList.add("hidden");
    });
  });
  // discount code (has to be through delegate as HTML is getting overriden)
  Rails.delegate(cart_form, ".discount_code_add", 'click', function(e) {
    discount_code_changed();
  });
  Rails.delegate(cart_form, ".discount_code_remove", 'click', function(e) {
    document.getElementById('discount_code_string_field').value = "";
    discount_code_changed();
  });
  Rails.delegate(cart_form, ".discount_code_field", 'keypress', function(e) {
    if(e.which == 13) {
      discount_code_changed();
      e.preventDefault();
    }
  });
  // shipping method
  Rails.delegate(cart_form, ".cart_shipping_method", 'change', function(e) {
    shipping_method_changed();
  });
  // payment method
  Rails.delegate(cart_form, ".cart_payment_method", 'change', function(e) {
    payment_method_changed();
  });


  // cart addresses/login tabs
  var cart_tabs = document.getElementById("cart_tabs");
  if(cart_tabs) {
    cart_tabs.querySelectorAll("a").forEach(function(a) {
      a.addEventListener("click", function(e) {
        e.preventDefault();
        var target = document.querySelector(a.getAttribute("href"));
        Array.prototype.forEach.call(target.parentNode.children, function(el) {
          el.classList.remove("show");
          el.classList.remove("active");
        });
        target.classList.add("show");
        target.classList.add("active");
        cart_tabs.querySelectorAll("a").forEach(function(sa) {
          sa.classList.remove("active");
        });
        a.classList.add("active");
      });
    });
  }

  // autofill same fields
  document.querySelectorAll(".autofillsamefields").forEach(function(field) {
    field.addEventListener("blur", function(e) {
      if(field.getAttribute("id").startsWith("order_addresses_attributes_0_") && !document.getElementById("order_addresses_attributes_1_user_address_id")) {
        var other = document.getElementById(field.getAttribute("id").replace("_attributes_0_", "_attributes_1_"));
        if(other && other.value == "") other.value = field.value;
      }
    });
  });

  // show company details fields
  var plus_shipping_company_details = document.getElementById("plus_shipping_company_details");
  var shipping_company_details = document.getElementById("shipping_company_details");
  var plus_billing_company_details = document.getElementById("plus_billing_company_details");
  var billing_company_details = document.getElementById("billing_company_details");
  if(plus_shipping_company_details) {
    plus_shipping_company_details.addEventListener("click", function(e) {
      e.preventDefault();
      plus_shipping_company_details.classList.add("hidden");
      if(plus_billing_company_details) plus_billing_company_details.classList.add("hidden");
      shipping_company_details.classList.add("show");
      if(billing_company_details) billing_company_details.classList.add("show");
    });
  }
  if(plus_billing_company_details) {
    plus_billing_company_details.addEventListener("click", function(e) {
      e.preventDefault();
      plus_billing_company_details.classList.add("hidden");
      billing_company_details.classList.add("show");
    });
  }

  // address select box filling fields
  document.querySelectorAll(".useraddresslist").forEach(function(select) {
    select.addEventListener("change", function(e) {
      var has_comany_details = false;
      var prefix = select.getAttribute("name").replace(/\[[^\]]+\]$/, ''); // remove last "[...]"
      var attrs = select.options[select.selectedIndex].attributes;
      for(var i = 0; i<attrs.length; i++) if(attrs[i].name.startsWith("data-")) {
        var key = attrs[i].name.replace("data-", "");
        var input = document.querySelector("input[name='"+prefix+"["+key+"]']");
        if(input) input.value = attrs[i].value;
        if((key == "company_name" || key == "company_id" || key == "vat_number") && input && (input.value || "").trim() != "") has_comany_details = true;
      }
      var save_to_user = document.querySelector("input[name='"+prefix+"[save_to_user]']");
      if(save_to_user) save_to_user.checked = false;
      if(has_comany_details) {
        var address_fields = closestWithClass(select, "address_fields");
        var plus_company_details = address_fields.querySelector(".plus_company_details");
        if(plus_company_details) plus_company_details.classList.add("hidden");
        var company_details = address_fields.querySelector(".company_details");
        if(company_details) company_details.classList.add("show");
      }
    });
  });

  // address same as shipping checkbox
  var same_as_shipping = document.getElementById("order_address_same_as_shipping");
  if(same_as_shipping) {
    same_as_shipping.addEventListener("change", function(e) {
      var collapse = Collapse.getOrCreateInstance(document.getElementById("billing_address"), { toggle: false });
      same_as_shipping.checked ? collapse.hide() : collapse.show();
    });
  }
}


// destination searcher
var destination_search_timeout = null;
var destination_search_instance = null;
function send_destination_search() {
  if(destination_search_timeout) {
    clearTimeout(destination_search_timeout);
    destination_search_timeout = null;
  }
  document.querySelector('#destination-searcher .signalizer').innerHTML = SPINNER_HTML;
  destination_search_timeout = setTimeout(function() {
    var instance = new Date().getTime();
    destination_search_instance = instance;
    var searcher = document.getElementById('destination-searcher');
    var query = []
    document.querySelectorAll("#destination-searcher input, .cart_shipping_destination").forEach(function(input) {
      if(input.name && (input.type != 'radio' || input.checked)) query.push(encodeURIComponent(input.name)+"="+encodeURIComponent(input.value));
    });
    query = query.join('&');
    Rails.ajax({
      type: "GET",
      url: searcher.getAttribute('data-url'),
      data: query,
      dataType: 'script',
      beforeSend: function(xhr, options) { //replace callback in order to stop processing of result when there is newer request already known
        var originalCallback = xhr.onreadystatechange;
        xhr.onreadystatechange = function() {
          if(destination_search_instance == instance) originalCallback();
        }
        return true;
      }
    });
  }, 250);
}
if(cart_form) {
  var sd_input = document.getElementById("order_shipping_destination");
  if(sd_input) {
    sd_input.addEventListener("keyup", function(event) {
      if(event.keyCode == 8 || event.keyCode > 32 && String.fromCharCode(event.keyCode).match(/^\S+$/i)) send_destination_search();
    });
    sd_input.addEventListener("keypress", function(event) {
      if(event.keyCode == 13) {
        send_destination_search();
        event.preventDefault();
      }
    });
    Rails.delegate(cart_form, ".cart_shipping_destination", 'change', function(e) {
      if(!this.checked) return;
      var cb = function() {
        document.getElementById("cart_action_source").value = "shipping_destination";
        document.getElementById("shipping_destination_detail").innerHTML = SPINNER_HTML;
      };
      send_cart_form(1, cb);
    });
  }
}
