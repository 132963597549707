import { closestWithClass } from "./utils.js"

var anyTopbarPopupFocused = false;
function topbarPopupFocused(e) {
  var popup = closestWithClass(this, "topbar-popup");
  popup.classList.add('focused');
  anyTopbarPopupFocused = true;
}
document.querySelectorAll(".topbar-popup input").forEach(function(input) {
  input.addEventListener('focus', topbarPopupFocused);
});
document.addEventListener('mousedown', function(e) {
  if(!anyTopbarPopupFocused) return;
  if(!closestWithClass(e.target, 'topbar-popup')) {
    document.querySelectorAll(".topbar-popup.focused").forEach(function(input) {
      input.classList.remove('focused');
    });
    anyTopbarPopupFocused = false;
  }
});
