import Rails from "@rails/ujs";

var cartButton = null;
function handleCartButtonClick(e) {
  cartButton = this;
}

function handleCartButtonSubmit(e) {
  this.querySelectorAll('.btn').forEach(function(btn) {
    btn.classList.add('disabled');
  });
  if(cartButton) cartButton.querySelector('.icon').setAttribute('class', 'icon icon-spinner');
  cartButton = null;
}

Rails.delegate(document, "form.cart-button button", 'click', handleCartButtonClick);
Rails.delegate(document, "form.cart-button", 'submit', handleCartButtonSubmit);


function handleCartButtonAmountChange(e) {
  var product_id = this.getAttribute("data-product-id");
  var prod = document.getElementById('product_'+product_id);
  if(prod) {
    prod.classList.remove('product-shipping-info-1');
    prod.classList.remove('product-shipping-info-2');
    var max = parseInt(this.getAttribute("data-max"));
    if(parseInt(this.value) < 1) this.value = 1;
    if(parseInt(this.value) > max) {
      prod.classList.add('product-shipping-info-2');
    } else {
      prod.classList.add('product-shipping-info-1');
    }
  }
}

Rails.delegate(document, "form.cart-button input.cart-button-amount", 'change', handleCartButtonAmountChange);
