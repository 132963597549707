import Rails from "@rails/ujs";
import { closestWithClass } from "./utils.js";

document.querySelectorAll('a.product-variant-more').forEach(function(link) {
  link.addEventListener('click', function(e) {
    link.parentNode.removeChild(link);
  });
});

//ajax loaded, can show up any time
Rails.delegate(document, "a.product_review_description_more_link", 'click', function(e) {
  closestWithClass(this, "product_review_description_truncated").classList.add("hidden");
});
