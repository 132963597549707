import { getWindowHeight, getScrollTop, scrollTop, getOffset, closestWithClass } from "./utils.js";
import { products_paginable_load } from "./filters-pagination";
import { visualizer_init, visualizer_opened } from "./visualizer";
import Rails from "@rails/ujs";

var quick_view_cache = {};
var quick_view_delay = null;
function quick_view_position() {
  var data = {
    opened: false,
    scrollTop: getScrollTop(),
    windowHeight: getWindowHeight(),
    viewOffset: 0,
    viewHeight: 0,
    screenOffset: 0,
    viewTopOnScreen: false,
    viewBottomOnScreen: false,
    calculate: function() {
      this.screenOffset = this.viewOffset - this.scrollTop;
      this.viewTopOnScreen = this.viewOffset >= this.scrollTop && this.viewOffset <= this.scrollTop+this.windowHeight;
      this.viewBottomOnScreen = this.viewOffset+this.viewHeight >= this.scrollTop && this.viewOffset+this.viewHeight <= this.scrollTop+this.windowHeight;
    }
  };
  if(document.querySelector(".products-product.quick-view")) {
    var view = document.querySelector(".products-product.quick-view .products-quick-view");
    data.opened = true;
    data.viewOffset = getOffset(view).top;
    data.viewHeight = view.offsetHeight;
    data.calculate();
  }
  return data;
}

function quick_view_html(product, html, loaded) {
  var quickview = product.querySelector(".products-quick-view");
  if(loaded) quickview.classList.add('loaded');
  quickview.innerHTML = html;
  product.querySelectorAll('.products-quick-view-link').forEach(function(el) {
    el.setAttribute('href', product.getAttribute('data-quickview-link'));
  });
  product.querySelectorAll('.products-quick-view .products-cart').forEach(function(el) {
    el.innerHTML = product.querySelector(".products-info .products-cart").innerHTML;
  });
  product.querySelectorAll('.products-quick-view .products-favourite').forEach(function(el) {
    el.innerHTML = product.querySelector(".products-info .products-favourite").innerHTML;
  });
  quickview.insertAdjacentHTML('beforeend', '<button type="button" class="btn-close" aria-label="Close"></button>');
  quickview.querySelector('.btn-close').addEventListener('click', quick_view_close);
  if(product.getAttribute("data-quickview-prev") == "true") {
    quickview.insertAdjacentHTML('beforeend', '<button type="button" class="btn btn-default products-quick-view-left"><span class="icon icon-angle-left" aria-hidden="true"></span></button>');
    product.querySelector(".products-quick-view-left").addEventListener("click", quick_view_prev);
  }
  if(product.getAttribute("data-quickview-next") == "true") {
    quickview.insertAdjacentHTML('beforeend', '<button type="button" class="btn btn-default products-quick-view-right"><span class="icon icon-angle-right" aria-hidden="true"></span></button>');
    product.querySelector(".products-quick-view-right").addEventListener("click", quick_view_next);
  }
  var visualizer = quickview.querySelector(".visualizer");
  if(visualizer) visualizer_init(visualizer);
}

function quick_view_show(event, elem, oldPosition) {
  if(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  var product = elem ? elem : closestWithClass(this, "products-product");
  var product_id = product.getAttribute("data-product-id");
  if(!product.querySelector(".products-quick-view")) {
    product.querySelector('.products-info').insertAdjacentHTML('beforeend', '<span class="products-quick-view-caret" aria-hidden="true"></span>');
    product.insertAdjacentHTML('beforeend', '<div class="products-quick-view"></div>');
  }
  if(!product.querySelector(".products-quick-view.loaded")) {
    if(quick_view_cache[product_id]) quick_view_html(product, quick_view_cache[product_id], true);
    else {
      quick_view_html(product, '<div style="display:block; width:100%; text-align:center; padding-top:120px;"><i class="icon icon-spinner" style="display:block; font-size:3rem; margin-bottom:1rem;"></i>Načítání produktu&hellip;</div>');
      if(quick_view_delay) clearTimeout(quick_view_delay);
      quick_view_delay = setTimeout(function() {
        quick_view_delay = null;
        Rails.ajax({
          type: "GET",
          url: "/quickview/"+product_id,
          dataType: 'html',
          success: function(response, statusText, xhr) {
            var html = response.body.innerHTML;
            quick_view_cache[product_id] = html;
            quick_view_html(product, html, true);
          }
        });
      }, 250);
    }
  }
  var old = oldPosition ? oldPosition : quick_view_position();
  document.querySelectorAll('.products-product.quick-view').forEach(function(el) {
    el.classList.remove('quick-view');
  });
  product.classList.add("quick-view");
  var view = product.querySelector(".products-quick-view");
  var now = quick_view_position();

  //scroll
  var offset = false;
  if(now.viewHeight < now.windowHeight) {
    if(old.opened) {
      var wasOnScreen = true;
      if(wasOnScreen = (old.viewTopOnScreen || old.viewBottomOnScreen)) {
        if(now.screenOffset != old.screenOffset) {
          now.scrollTop += now.screenOffset - old.screenOffset;
          now.calculate();
          scrollTop(now.scrollTop);
        }
      }
      if((!now.viewTopOnScreen && !now.viewBottomOnScreen) || !wasOnScreen) { //view is not on the screen at all
        offset = true; // make it like newly opened
      } else if(now.viewTopOnScreen && !now.viewBottomOnScreen) { //top of view is on screen, bottom not
        offset = now.viewOffset - (now.windowHeight - now.viewHeight) + 5;
      } else if(!now.viewTopOnScreen && now.viewBottomOnScreen) { //bottom of view is on screen, top not
        offset = now.viewOffset - 5;
      }
    } else offset = true //newly opened, scroll to it
  } else {
    offset = now.viewOffset; //doesnt fit, scroll to top
  }
  if(offset === true) { // scroll to newly opened - make it clever
    //offset = now.viewOffset - ((now.windowHeight / 2) - (now.viewHeight / 2)); // simple center
    // make it appear in the center of screen but if possible try to fit as much of the products row as possible without moving it too down
    var pad = ((now.windowHeight / 2) - ((now.viewHeight + product.offsetHeight) / 2));
    if(pad < now.windowHeight/6) pad = now.windowHeight/6;
    offset = now.viewOffset - (now.windowHeight - now.viewHeight - pad);
  }
  if(offset !== false) scrollTop(offset);
}

function quick_view_close(event) {
  document.querySelectorAll('.products-product.quick-view').forEach(function(el) {
    el.classList.remove('quick-view');
  });
}

function quick_view_prev(event) {
  var current = document.querySelector(".products-product.quick-view");
  if(current) {
    var all = document.querySelectorAll('.products-product');
    var currentIndex = Array.prototype.indexOf.call(all, current);
    if(currentIndex >= 1) {
      quick_view_show(null, all[currentIndex-1]);
    } else {
      var prevlink = document.querySelector("#products_paginable ul.pagination li.prev:not(.disabled) a");
      if(prevlink) {
        products_paginable_load(prevlink.href);
        quick_view_autoshow = {which: "last", position: quick_view_position()};
      }
    }
  }
}
function quick_view_next(event) {
  var current = document.querySelector(".products-product.quick-view");
  if(current) {
    var all = document.querySelectorAll('.products-product');
    var currentIndex = Array.prototype.indexOf.call(all, current);
    if(currentIndex <= all.length-2) {
      quick_view_show(null, all[currentIndex+1]);
    } else {
      var nextlink = document.querySelector("#products_paginable ul.pagination li.next:not(.disabled) a");
      if(nextlink) {
        products_paginable_load(nextlink.href);
        quick_view_autoshow = {which: "first", position: quick_view_position()};
      }
    }
  }
}

var quick_view_autoshow = false;
export function quick_view_init(or_scroll_up) { //export for pagination
  setTimeout(function(){
    if(quick_view_autoshow !== false) {
      var all = document.querySelectorAll('.products-product');
      if(quick_view_autoshow.which == "first") {
        if(all.length > 0) quick_view_show(null, all[0], quick_view_autoshow.position);
      } else if(quick_view_autoshow.which == "last") {
        if(all.length > 0) quick_view_show(null, all[all.length-1], quick_view_autoshow.position);
      }
      quick_view_autoshow = false;
    } else if(or_scroll_up) {
      var viewTop = getOffset(document.getElementById("products_paginable")).top;
      if(viewTop < getScrollTop()) scrollTop(viewTop - 10);
    }
  }, 50);
}

Rails.delegate(document, ".products-quick-view-button", 'click', quick_view_show);

document.addEventListener('keydown', function(e) {
  if(document.querySelector(".products-product.quick-view")) {
    switch(e.which) {
      case 37: // left
        if(!visualizer_opened() && document.querySelector(".products-product.quick-view .products-quick-view .products-quick-view-left") && !document.querySelector("#products_paginable_loading.show")) {
          e.preventDefault();
          e.stopPropagation();
          quick_view_prev();
        }
        break;
      case 39: // right
        if(!visualizer_opened() && document.querySelector(".products-product.quick-view .products-quick-view .products-quick-view-right") && !document.querySelector("#products_paginable_loading.show")) {
          e.preventDefault();
          e.stopPropagation();
          quick_view_next();
        }
        break;
      case 27: // esc
        if(!visualizer_opened() && document.querySelector(".products-product.quick-view .products-quick-view .btn-close")) {
          e.preventDefault();
          e.stopPropagation();
          quick_view_close();
        }
        break;
    }
  }
});
