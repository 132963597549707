import { getScrollTop, scrollTop, getOffset } from "./utils.js";
import Rails from "@rails/ujs";

// radio buttons as stars
document.querySelectorAll(".rating-field").forEach(function(container) {
  container.querySelectorAll("input").forEach(function(input) {
    input.addEventListener('change', function(e) {
      container.querySelector(".rating-field-hint").innerHTML = '<span>'+input.value+'</span>/5';
    });
  });
});

// reviews show
// pagination
function product_reviews_loading() {
  document.getElementById('product_reviews_loading').classList.add('show');
  document.getElementById('product_reviews_loading_dialog').classList.add('show');
}

function product_reviews_finished() {
  var viewTop = getOffset(document.getElementById("product_reviews")).top;
  if(viewTop < getScrollTop()) scrollTop(viewTop - 100);
}

export function product_reviews_load(url) { // export for quick view
  Rails.ajax({
    type: "GET",
    url: url,
    dataType: 'script',
    success: function(response, statusText, xhr) {
      product_reviews_finished();
    }
  });
  product_reviews_loading();
  if(history.replaceState) history.replaceState(null, document.title, url);
}

function handleProductsReviewsClick(e) {
  product_reviews_load(this.href);
  e.preventDefault();
}

var product_reviews = document.getElementById("product_reviews");
if(product_reviews) {
  Rails.delegate(product_reviews, ".pagination li:not(.disabled) a", 'click', handleProductsReviewsClick);
}
