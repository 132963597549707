function rangeSlider(slider) {
  var self = this;

  slider.innerHTML = '<div class="range-slider-touch range-slider-touch-left"></div><div class="range-slider-touch range-slider-touch-right"></div><div class="range-slider-line"><span></span></div>';
  var touchLeft  = slider.querySelector('.range-slider-touch-left');
  var touchRight = slider.querySelector('.range-slider-touch-right');
  var lineSpan   = slider.querySelector('.range-slider-line span');

  var min = parseFloat(slider.getAttribute('data-min'));
  var max = parseFloat(slider.getAttribute('data-max'));
  var defaultMinValue = min;
  if(slider.hasAttribute('data-min-value')) defaultMinValue = parseFloat(slider.getAttribute('data-min-value'));
  var defaultMaxValue = max;
  if(slider.hasAttribute('data-max-value')) defaultMaxValue = parseFloat(slider.getAttribute('data-max-value'));
  var step = 0.0;
  if(slider.getAttribute('data-step')) step = Math.abs(parseFloat(slider.getAttribute('data-step')));

  if(defaultMinValue < min) defaultMinValue = min;
  if(defaultMaxValue > max) defaultMaxValue = max;
  if(defaultMinValue > defaultMaxValue) defaultMinValue = defaultMaxValue;

  self.slider = slider;
  self.min = min;
  self.max = max;
  self.minValue = defaultMinValue;
  self.maxValue = defaultMaxValue;

  var rangeSize = 0;
  self.setMinValue = function(value) {
    if(value < self.min) value = self.min;
    else if(value > self.maxValue) value = self.maxValue;
    slider.setAttribute('data-min-value', value);
    self.minValue = value;
    reset();
    if(self.onChange) self.onChange(self.minValue, self.maxValue);
    if(self.didChanged) self.didChanged(self.minValue, self.maxValue);
  }
  self.setMaxValue = function(value) {
    if(value < self.minValue) value = self.minValue;
    else if(value > self.max) value = self.max;
    slider.setAttribute('data-max-value', value);
    self.maxValue = value;
    reset();
    if(self.onChange) self.onChange(self.minValue, self.maxValue);
    if(self.didChanged) self.didChanged(self.minValue, self.maxValue);
  }

  function reset() {
    rangeSize = slider.offsetWidth - touchLeft.offsetWidth - touchRight.offsetWidth;
    var ratio = ((parseFloat(slider.getAttribute('data-min-value')) - min) / (max - min));
    touchLeft.style.left = Math.ceil(ratio * rangeSize) + 'px';
    ratio = ((parseFloat(slider.getAttribute('data-max-value')) - min) / (max - min));
    touchRight.style.left = Math.ceil(ratio * rangeSize + touchLeft.offsetWidth) + 'px';
    updateSliderSpan();
  };

  function updateSliderSpan() {
    //lineSpan.style.marginLeft = touchLeft.offsetLeft + 'px';
    //lineSpan.style.width = (touchRight.offsetLeft - touchLeft.offsetLeft) + 'px'; // absolutely values stretch the element when window is resized down :(
    lineSpan.style.marginLeft = (touchLeft.offsetLeft * 100.0 / (slider.offsetWidth - touchLeft.offsetWidth)) + '%';
    lineSpan.style.width = ((touchRight.offsetLeft - touchLeft.offsetLeft) * 100.0 / (slider.offsetWidth - touchLeft.offsetWidth)) + '%';
  }

  slider.setAttribute('data-min-value', defaultMinValue);
  slider.setAttribute('data-max-value', defaultMaxValue);
  reset();

  var selectedTouch = null;
  var startX = 0, maxX = 0;
  function onStart(event) {
    // Prevent default dragging of selected content
    event.preventDefault();
    var eventTouch = event;
    if(event.touches) eventTouch = event.touches[0];

    if(this === touchRight) maxX = slider.offsetWidth - touchRight.offsetWidth;

    startX = eventTouch.pageX - this.offsetLeft;
    selectedTouch = this;
    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onStop);
    document.addEventListener('touchmove', onMove);
    document.addEventListener('touchend', onStop);
  }

  function onMove(event) {
    var eventTouch = event;
    if(event.touches) eventTouch = event.touches[0];

    var x = eventTouch.pageX - startX;

    if(selectedTouch === touchLeft) {
      var m = touchRight.offsetLeft - selectedTouch.offsetWidth;
      if(x > m) x = m;
      else if(x < 0) x = 0;
      selectedTouch.style.left = x + 'px';
    } else if (selectedTouch === touchRight) {
      var m = touchLeft.offsetLeft + touchLeft.offsetWidth;
      if(x < m) x = m;
      else if(x > maxX) x = maxX;
      selectedTouch.style.left = x + 'px';
    }

    updateSliderSpan();
    calculateValue();

    if(self.onChange) self.onChange(self.minValue, self.maxValue);
  }

  function onStop(event) {
    document.removeEventListener('mousemove', onMove);
    document.removeEventListener('mouseup', onStop);
    document.removeEventListener('touchmove', onMove);
    document.removeEventListener('touchend', onStop);

    selectedTouch = null;

    updateSliderSpan();
    calculateValue();

    if(self.didChanged) self.didChanged(self.minValue, self.maxValue);
  }

  function calculateValue() {
    var minValue = touchLeft.offsetLeft / rangeSize;
    var maxValue = (touchRight.offsetLeft - touchLeft.offsetWidth) / rangeSize;

    minValue = minValue * (max - min) + min;
    maxValue = maxValue * (max - min) + min;

    if(step !== 0.0) {
      var multi = Math.floor((minValue / step));
      minValue = step * multi;

      multi = Math.floor((maxValue / step));
      maxValue = step * multi;
    }

    slider.setAttribute('data-min-value', minValue);
    slider.setAttribute('data-max-value', maxValue);
    self.minValue = minValue;
    self.maxValue = maxValue;

    //console.log("calculateValue "+minValue+" "+maxValue);
  }

  // link events
  touchLeft.addEventListener('mousedown', onStart);
  touchLeft.addEventListener('touchstart', onStart);
  touchRight.addEventListener('mousedown', onStart);
  touchRight.addEventListener('touchstart', onStart);
  window.addEventListener('resize', reset);
};

export default rangeSlider;
