import { reflow, closestWithClass, passiveFalseIfSupported } from "./utils.js";
import Backdrop from 'bootstrap/js/src/util/backdrop';
import ScrollBarHelper from 'bootstrap/js/src/util/scrollbar';

var PREVIEW_SIZE = 490;
var MAX_ZOOM_SIZE = 1080.0;

//opened visualizer
var visualizerBackdrop = null;
var openedVisualizer = null;
var openedVisualizerPlaceholder = null;
var visualizerOpeningLeft = 0, visualizerOpeningTop = 0;
function visualizer_open(visualizer, do_not_init) {
  if(openedVisualizer) return;
  var closedSize = visualizer.offsetWidth;
  var closedPos = visualizer.getBoundingClientRect();
  openedVisualizerPlaceholder = document.createElement('div');
  openedVisualizerPlaceholder.style.width = "100%";
  openedVisualizerPlaceholder.style.height = visualizer.offsetHeight+"px";
  // TODO: maybe show original image in placeholder?
  visualizer.parentNode.insertBefore(openedVisualizerPlaceholder, visualizer);
  if(!visualizerBackdrop) {
    visualizerBackdrop = new Backdrop({
      className: "visualizer-backdrop",
      isAnimated: true,
      clickCallback: function() { visualizer_close(); }
    });
  }
  visualizerBackdrop.show();
  new ScrollBarHelper().hide();
  visualizer.classList.remove('animate');
  visualizer.classList.add("opened");

  visualizer.querySelectorAll('.visualizer-item img').forEach(function(img) { //do not show tooltip next to mouse
    img.removeAttribute("title");
  });

  var vw = visualizer.offsetWidth, vh = visualizer.offsetHeight;
  var imgContainer = visualizer.querySelector('.visualizer-item.active a');
  imgContainer.style.width = imgContainer.style.height = closedSize+"px";
  visualizerOpeningLeft = closedPos.x - (vw-closedSize)/2;
  visualizerOpeningTop = closedPos.y - (vh-closedSize)/2;
  imgContainer.style.transform = "translate("+visualizerOpeningLeft+"px, "+visualizerOpeningTop+"px)";
  reflow(imgContainer);
  visualizer.classList.add('animate');
  //imgContainer.style.transform = "";
  visualizer.addEventListener('mousemove', visualizer_mouse_move);
  openedVisualizer = visualizer;
  visualizer_resize();
  visualizer_slided(do_not_init);
}
function visualizer_close() {
  if(!openedVisualizer) return;
  openedVisualizer.parentNode.removeChild(openedVisualizerPlaceholder);
  visualizerBackdrop.hide();
  new ScrollBarHelper().reset();
  openedVisualizer.classList.remove('animate');
  openedVisualizer.querySelectorAll('.visualizer-item a').forEach(function(a) {
    a.style.width = a.style.height = a.style.transform = "";
  });
  openedVisualizer.classList.remove("opened");
  openedVisualizer.removeEventListener('mousemove', visualizer_mouse_move);
  openedVisualizer = null;
}
var visualizerOpenedVW, visualizerOpenedVH, visualizerOpenedSize;
var visualizerMaxScale;
function visualizer_resize(vw, vh) { //called when window changes size
  if(!vw) vw = openedVisualizer.offsetWidth;
  if(!vh) vh = openedVisualizer.offsetHeight;
  visualizerOpenedVW = vw;
  visualizerOpenedVH = vh;
  visualizerOpenedSize = (vw > vh ? vh : vw);
  if(visualizerOpenedSize > 750) visualizerOpenedSize = 750;
  openedVisualizer.querySelectorAll('.visualizer-item a').forEach(function(a) {
    a.style.width = a.style.height = visualizerOpenedSize+"px";
  });
  visualizerMaxScale = MAX_ZOOM_SIZE/visualizerOpenedSize;
}
function visualizer_slided(do_not_init) { //called when slide is changed to initialize it and reset others
  if(!openedVisualizer) return;
  visualizerScale = 1.0;
  visualizerWheelUsed = false;
  openedVisualizer.classList.remove("wheel_zoom");
  if(do_not_init === true) return;
  openedVisualizer.querySelectorAll('.visualizer-item a').forEach(function(a) {
    a.style.transform = "";
  });
  visualizer_big_check();
}
function visualizer_big_check() { //check if current item needs bigger image
  if(!openedVisualizer) return;
  if(visualizerOpenedSize*visualizerScale > PREVIEW_SIZE*1.25) {
    var imgContainer = openedVisualizer.querySelector(".visualizer-item.active a");
    if(!imgContainer.querySelector('img.visualizer-big')) {
      var big = document.createElement('img');
      big.classList.add('visualizer-big');
      big.addEventListener('load', visualizer_big_loaded);
      big.src = imgContainer.href;
      imgContainer.appendChild(big);
      imgContainer.parentNode.insertAdjacentHTML('beforeend', '<i class="icon icon-spinner hidden"></i>');
      setTimeout(function() {
        var spinner = imgContainer.parentNode.querySelector('.icon-spinner');
        if(spinner) spinner.classList.remove('hidden');
      }, 750);
    }
  }
}
function visualizer_big_loaded() {
  var w = this.naturalWidth, h = this.naturalHeight;
  if(w > h) {
    this.style.height = (h*100/w)+"%";
    this.style.top = (50-h*50/w)+"%";
  } else if(h > w) {
    this.style.width = (w*100/h)+"%";
    this.style.left = (50-w*50/h)+"%";
  }
  var spinner = this.parentNode.parentNode.querySelector('.icon-spinner');
  if(spinner) this.parentNode.parentNode.removeChild(spinner);
}
var visualizerScale = 1.0;
var visualizerWheelUsed = false;
function visualizer_wheel_zoom(event) { //zooming big image by mouse wheel
  if(!openedVisualizer) return;
  //event.preventDefault(); //disables back gesture on mac
  openedVisualizer.classList.add("wheel_zoom");
  visualizerScale += event.deltaY * 0.01;
  visualizerScale = Math.min(Math.max(1, visualizerScale), visualizerMaxScale);
  visualizerWheelUsed = visualizerScale != 1.0;
  visualizer_reposition_by_mouse();
  visualizer_big_check();
}
var visualizerMouseX = 0, visualizerMouseY = 0;
function visualizer_mouse_move(event) { //moving mouse over zoomed image to "scroll" the view
  if(!openedVisualizer) return;
  visualizerMouseX = event.clientX;
  visualizerMouseY = event.clientY;
  if(visualizerWheelUsed) visualizer_reposition_by_mouse();
}
function visualizer_reposition_by_mouse() {
  if(!openedVisualizer) return;
  openedVisualizer.classList.remove('animate');
  var transform = "";
  if(visualizerWheelUsed) {
    var dx = (-visualizerMouseX/visualizerOpenedVW+0.5)*Math.max((visualizerOpenedSize*visualizerScale-visualizerOpenedVW), 0);
    var dy = (-visualizerMouseY/visualizerOpenedVH+0.5)*Math.max((visualizerOpenedSize*visualizerScale-visualizerOpenedVH), 0);
    transform += "translate("+dx+"px, "+dy+"px)";
    transform += " scale("+visualizerScale+")";
  }
  var imgContainer = openedVisualizer.querySelector('.visualizer-item.active a');
  imgContainer.style.transform = transform;
}


// touch pinch zoom gesture
function touches_dist(e) {
  var dw = e.touches[0].pageX - e.touches[1].pageX, dh = e.touches[0].pageY - e.touches[1].pageY;
  return Math.sqrt(dw*dw + dh*dh);
}
var visualizerPinching = false; // is being set below
//var activeVisualizer used from below
var visualizerPinchStartDist = 0, visualizerPinchStartScale = 0, visualizerPitchStartCenterX = 0, visualizerPitchStartCenterY = 0;
function visualizer_pinch_start(event) {
  //console.log("pinch start: "+event);
  event.preventDefault();
  if(!openedVisualizer) visualizer_open(activeVisualizer, true);
  visualizerPinchStartDist = touches_dist(event);
  visualizerPinchStartScale = visualizerScale;
  var visualizerItem = openedVisualizer.querySelector('.visualizer-item.active');
  //calculate pixel position of fingers center point on 1x scaled image
  visualizerPitchStartCenterX = ((event.touches[0].clientX + event.touches[1].clientX)/2 + visualizerItem.scrollLeft - Math.max((visualizerOpenedVW-visualizerOpenedSize*visualizerScale)/2, 0))/visualizerScale;
  visualizerPitchStartCenterY = ((event.touches[0].clientY + event.touches[1].clientY)/2 + visualizerItem.scrollTop - Math.max((visualizerOpenedVH-visualizerOpenedSize*visualizerScale)/2, 0))/visualizerScale;
  openedVisualizer.classList.remove('animate');
}
var visualizerPinchedX = 0, visualizerPinchedY = 0;
function visualizer_pinch_move(event) {
  //console.log("pinch move: "+event);
  event.preventDefault();
  visualizerScale = touches_dist(event) / visualizerPinchStartDist * visualizerPinchStartScale;
  visualizerScale = Math.min(Math.max(visualizerPinchStartScale > 1.05 ? 1 : 0.25, visualizerScale), visualizerMaxScale);
  visualizerPinchedX = Math.max((visualizerOpenedSize*visualizerScale-visualizerOpenedVW)/2, 0);
  visualizerPinchedY = Math.max((visualizerOpenedSize*visualizerScale-visualizerOpenedVH)/2, 0);
  var imgContainer = openedVisualizer.querySelector('.visualizer-item.active a');
  imgContainer.style.transform = "translate("+(visualizerPinchedX+visualizerOpeningLeft)+"px, "+(visualizerPinchedY+visualizerOpeningTop)+"px) scale("+visualizerScale+")";

  var centerX = (event.touches[0].clientX + event.touches[1].clientX)/2;
  var centerY = (event.touches[0].clientY + event.touches[1].clientY)/2;
  //calculate new position of starting center pixel inside of visualizer-item
  var startCenterX = Math.max((visualizerOpenedVW-visualizerOpenedSize*visualizerScale)/2, 0) + visualizerPitchStartCenterX*visualizerScale;
  var startCenterY = Math.max((visualizerOpenedVH-visualizerOpenedSize*visualizerScale)/2, 0) + visualizerPitchStartCenterY*visualizerScale;
  //and move it to be under new center position
  imgContainer.parentNode.scrollLeft = Math.max(startCenterX - centerX, 0);
  imgContainer.parentNode.scrollTop = Math.max(startCenterY - centerY, 0);
}
function visualizer_pinch_stop(event) {
  //console.log("pinch stop: "+event);
  event.preventDefault();
  if(visualizerScale < 1) visualizer_close();
  else {
    if(visualizerOpeningLeft != 0 || visualizerOpeningTop != 0) {
      visualizerOpeningLeft = 0;
      visualizerOpeningTop = 0;
      openedVisualizer.classList.add('animate');
      var imgContainer = openedVisualizer.querySelector('.visualizer-item.active a');
      imgContainer.style.transform = "translate("+visualizerPinchedX+"px, "+visualizerPinchedY+"px) scale("+visualizerScale+")";
    }
    visualizer_big_check();
  }
}


// sliding animation handling
var ANIMATION_DURATION = 405;
var animationCallback = null;
var animationTimeoutObj = null;
function finish_animation_now() {
  if(animationCallback) {
    animationCallback();
    animationCallback = null;
  }
  if(animationTimeoutObj) {
    clearTimeout(animationTimeoutObj);
    animationTimeoutObj = null;
  }
}
function animationTimeout(callback) {
  animationCallback = callback;
  animationTimeoutObj = setTimeout(finish_animation_now, ANIMATION_DURATION);
}

function visualizer_update_counter(visualizer, current) {
  var all = visualizer.querySelectorAll('.visualizer-item');
  if(!current) current = visualizer.querySelector('.visualizer-item.active');
  var currentIndex = Array.prototype.indexOf.call(all, current);
  visualizer.querySelector('.visualizer-counter').innerText = (currentIndex+1)+"/"+all.length;
  //activate thumbnail
  var thumbnails = visualizer.parentNode.querySelector('.visualizer-thumbnails');
  if(thumbnails) {
    thumbnails.querySelectorAll('a').forEach(function(a, index) {
      if(index == currentIndex) a.classList.add('active');
      else a.classList.remove('active');
    });
  }
}

// arrows clicking, direct sliding
function visualizer_slide_to(visualizer, index) { visualizer_slide(visualizer, 0, index); }
function visualizer_slide(visualizer, dir, index) {
  if(visualizer.querySelector('.visualizer-item-prev, .visualizer-item-next')) return;
  var all = visualizer.querySelectorAll('.visualizer-item');
  if(all.length <= 1) return;
  var current = visualizer.querySelector('.visualizer-item.active');
  var currentIndex = Array.prototype.indexOf.call(all, current);
  var next, nextClass, dirClass;
  if(dir == 0) { //direct slide to index
    if(index == currentIndex) return;
    next = all[index];
    nextClass = index > currentIndex ? 'visualizer-item-next' : 'visualizer-item-prev';
    dirClass = index > currentIndex ? 'visualizer-item-left' : 'visualizer-item-right';
  } else {
    next = dir > 0 ? all[currentIndex == all.length-1 ? 0 : currentIndex+1] : all[currentIndex == 0 ? all.length-1 : currentIndex-1];
    nextClass = dir > 0 ? 'visualizer-item-next' : 'visualizer-item-prev';
    dirClass = dir > 0 ? 'visualizer-item-left' : 'visualizer-item-right';
  }
  visualizerWheelUsed = false; //stop processing mousemove over image which can mess with animation class
  visualizer.classList.remove('animate');
  finish_animation_now();
  visualizer.classList.add('animate');
  next.classList.add(nextClass);
  reflow(next);
  current.classList.add(dirClass);
  next.classList.add(dirClass);
  visualizer_update_counter(visualizer, next);
  animationTimeout(function() {
    next.classList.remove(dirClass);
    current.classList.remove(dirClass);
    next.classList.remove(nextClass);
    next.classList.add('active');
    current.classList.remove('active');
    visualizer_update_counter(visualizer);
    visualizer_slided();
  });
}

// touch/mouse sliding
var activeVisualizer = null, activePrev, activeCurrent, activeNext, activeDir; // 1 moving from right to left, -1 moving from left to right
var activeVisualizerSingleItem = false;
var startX = 0, startY = 0, deltaX = 0, deltaY = 0, width = 0, height = 0;
var startTime = 0;
var dirDetected = false, dirScrolling = false, dirClosing = false;
var ignoreNextClick = false;
var CLOSING_MOVEMENT_MULTIPLIER = 1.5;
function visualizer_move_start(event) {
  //console.log("start: "+event.touches.length +"already pinching"+visualizerPinching);
  document.addEventListener('mousemove', visualizer_move);
  document.addEventListener('mouseup', visualizer_move_stop);
  document.addEventListener('touchmove', visualizer_move, passiveFalseIfSupported);
  document.addEventListener('touchend', visualizer_move_stop, false);
  document.addEventListener('touchcancel', visualizer_move_stop, false);
  if(visualizerPinching) return;
  finish_animation_now();

  var eventTouch = event;
  if(event.touches) {
    eventTouch = event.touches[0];
    if(event.touches.length == 2) {
      visualizerPinching = true;
      if(activeVisualizer) { //start already run when first finger touched
        if(dirDetected && !dirScrolling) {//may be moved to side
          activePrev.style.transform = "";
          activeCurrent.style.transform = "";
          activeCurrent.style.opacity = 1;
          activeNext.style.transform = "";
          activePrev.classList.remove('visualizer-item-prev');
          activeNext.classList.remove('visualizer-item-next');
        }
      } else activeVisualizer = this.parentNode;
      ignoreNextClick = true;
      visualizer_pinch_start(event);
      return;
    }
  }

  startX = eventTouch.pageX;
  startY = eventTouch.pageY;
  deltaX = 0;
  deltaY = 0;
  startTime = new Date().getTime();
  dirDetected = false;
  dirScrolling = false;
  dirClosing = false;
  ignoreNextClick = false;
  activeVisualizer = this.parentNode;
  activeVisualizer.classList.remove('animate');
  var all = activeVisualizer.querySelectorAll('.visualizer-item');
  activeVisualizerSingleItem = all.length <= 1;
  activeCurrent = activeVisualizer.querySelector('.visualizer-item.active');
  width = activeCurrent.offsetWidth;
  height = activeCurrent.offsetHeight;
  var currentIndex = Array.prototype.indexOf.call(all, activeCurrent);
  activePrev = all[currentIndex == 0 ? all.length-1 : currentIndex-1];
  activeNext = all[currentIndex == all.length-1 ? 0 : currentIndex+1];
  activeDir = 0;
}
function visualizer_move(event) {
  //console.log("move");
  if(visualizerPinching) return visualizer_pinch_move(event);
  if(dirScrolling) return;

  var eventTouch = event;
  if(event.touches) eventTouch = event.touches[0];

  if(!dirDetected) {
    var dX = Math.abs(eventTouch.pageX - startX), dY = Math.abs(eventTouch.pageY - startY);
    if(dX > 0 || dY > 0) {
      dirDetected = true;
      if(dY > dX) { //vertical
        if(!openedVisualizer) { //always scroll
          dirScrolling = true;
          return;
        }
        if(event.touches && openedVisualizer && !visualizerWheelUsed && visualizerScale > 1.0 && visualizerOpenedSize*visualizerScale > visualizerOpenedVH) { //vertical scrolling is possible
          var activeItem = openedVisualizer.querySelector('.visualizer-item.active');
          if((eventTouch.pageY > startY && activeItem.scrollTop > 3) ||
            (eventTouch.pageY < startY && activeItem.scrollTop < visualizerOpenedSize*visualizerScale-visualizerOpenedVH-3)) {
            dirScrolling = true;
            return;
          }
        }
        dirClosing = true;
      } else { //horizontal
        if(event.touches && openedVisualizer && !visualizerWheelUsed && visualizerScale > 1.0 && visualizerOpenedSize*visualizerScale > visualizerOpenedVW) { //horizontal scrolling is possible
          var activeItem = openedVisualizer.querySelector('.visualizer-item.active');
          if((eventTouch.pageX > startX && activeItem.scrollLeft > 3) ||
            (eventTouch.pageX < startX && activeItem.scrollLeft < visualizerOpenedSize*visualizerScale-visualizerOpenedVW-3)) {
            dirScrolling = true;
            return;
          }
        }
      }
      ignoreNextClick = true;
    } else return;
  }

  if(activeVisualizerSingleItem) return;
  event.preventDefault();
  if(dirClosing) {
    deltaY = eventTouch.pageY - startY;
    activeCurrent.style.transform = "translateY("+(deltaY*100*CLOSING_MOVEMENT_MULTIPLIER/height)+"%)";
    activeCurrent.style.opacity = Math.max(1.0 - deltaY*CLOSING_MOVEMENT_MULTIPLIER/height, 0);
  } else { // dir sliding
    deltaX = eventTouch.pageX - startX;
    activeCurrent.style.transform = "translateX("+(deltaX*100/width)+"%)";
    if(deltaX > 0) { //sliding right
      if(activeDir == 1) activeNext.classList.remove('visualizer-item-next');
      if(activeDir != -1) activePrev.classList.add('visualizer-item-prev');
      activePrev.style.transform = "translateX("+(-100 + deltaX*100/width)+"%)";
      activeDir = -1;
    } else if(deltaX < 0) { // sliding left
      if(activeDir == -1) activePrev.classList.remove('visualizer-item-prev');
      if(activeDir != 1) activeNext.classList.add('visualizer-item-next');
      activeNext.style.transform = "translateX("+(100 + deltaX*100/width)+"%)";
      activeDir = 1;
    }
  }
}
function visualizer_move_stop(event) {
  //console.log("stop");
  document.removeEventListener('mousemove', visualizer_move);
  document.removeEventListener('mouseup', visualizer_move_stop);
  document.removeEventListener('touchmove', visualizer_move, passiveFalseIfSupported);
  document.removeEventListener('touchend', visualizer_move_stop, false);
  document.removeEventListener('touchcancel', visualizer_move_stop, false);

  if(visualizerPinching) {
    visualizer_pinch_stop(event);
    activeVisualizer = null;
    visualizerPinching = false;
    return;
  }
  if(activeVisualizerSingleItem) return;

  var originalVisualizerWheelUsed = visualizerWheelUsed;
  visualizerWheelUsed = false; //stop processing mousemove over image which can mess with animation class

  activeVisualizer.classList.add('animate');
  activePrev.style.transform = "";
  activeCurrent.style.transform = "";
  activeCurrent.style.opacity = 1;
  activeNext.style.transform = "";

  var deltaTime = new Date().getTime() - startTime;
  if(dirClosing) {
    var velocity = Math.abs(deltaY*1000/deltaTime); //px/s
    if(velocity > 400) deltaY *= velocity/400;

    if(Math.abs(deltaY*CLOSING_MOVEMENT_MULTIPLIER) > height*0.4) visualizer_close();
  } else { // dir sliding
    var velocity = Math.abs(deltaX*1000/deltaTime); //px/s
    if(velocity > 400) deltaX *= velocity/400;

    var current = activeCurrent, next = null, nextClass, dirClass;
    if(deltaX > width*0.4) {
      next = activePrev;
      nextClass = 'visualizer-item-prev';
      dirClass = 'visualizer-item-right';
    } else if(deltaX < -width*0.4) {
      next = activeNext;
      nextClass = 'visualizer-item-next';
      dirClass = 'visualizer-item-left';
    }

    if(next) { // advance to new
      var visualizer = activeVisualizer;
      current.classList.add(dirClass);
      next.classList.add(dirClass);
      visualizer_update_counter(visualizer, next);
      animationTimeout(function() {
        next.classList.remove(dirClass);
        current.classList.remove(dirClass);
        next.classList.remove(nextClass);
        next.classList.add('active');
        current.classList.remove('active');
        visualizer_update_counter(visualizer);
        visualizer_slided();
      });
    } else { // return back
      next = activeNext;
      var prev = activePrev;
      animationTimeout(function(){
        visualizerWheelUsed = originalVisualizerWheelUsed;
        prev.classList.remove('visualizer-item-prev');
        next.classList.remove('visualizer-item-next');
      });
    }
  }

  activeVisualizer = null;
}




export function visualizer_init(element) {
  var all = element.querySelectorAll('.visualizer-item');
  element.insertAdjacentHTML('beforeend', '<button type="button" class="btn-close" title="Zavřít"></button>');
  element.querySelector('.btn-close').addEventListener("click", function(e){ visualizer_close(); e.stopPropagation(); });
  if(all.length > 1) {
    element.insertAdjacentHTML('beforeend', '<button type="button" class="visualizer-left" title="Předchozí obrázek"><span class="visualizer-icon-left" aria-hidden="true"></span></button>');
    element.querySelector(".visualizer-left").addEventListener("click", function(e) { visualizer_slide(element, -1); e.stopPropagation(); });
    element.insertAdjacentHTML('beforeend', '<button type="button" class="visualizer-right" title="Následující obrázek"><span class="visualizer-icon-right" aria-hidden="true"></span></button>');
    element.querySelector(".visualizer-right").addEventListener("click", function(e) { visualizer_slide(element, 1); e.stopPropagation(); });
    element.insertAdjacentHTML('beforeend', '<span class="visualizer-counter">1/'+all.length+'</span>');
  }
  element.addEventListener('click', function(e) {
    if(ignoreNextClick) ignoreNextClick = false;
    else visualizer_close();
  });
  var inner = element.querySelector('.visualizer-inner');
  inner.addEventListener('wheel', visualizer_wheel_zoom, passiveFalseIfSupported);
  inner.addEventListener('mousedown', visualizer_move_start);
  inner.addEventListener('touchstart', visualizer_move_start, passiveFalseIfSupported);
  inner.querySelectorAll('a').forEach(function(a) {
    a.setAttribute("draggable", "false");
    a.addEventListener('click', function(e) {
      if(ignoreNextClick) ignoreNextClick = false;
      else visualizer_open(element);
      e.stopPropagation();
      e.preventDefault();
    });
  });
  //thumbnails
  var thumbnails = element.parentNode.querySelector('.visualizer-thumbnails');
  if(thumbnails) {
    thumbnails.querySelectorAll('a').forEach(function(a, index) {
      if(index == 0) a.classList.add('active');
      a.addEventListener('click', function(e) {
        visualizer_slide_to(element, index);
        e.preventDefault();
      });
    });
  }
}

export function visualizer_opened() {
  return openedVisualizer != null;
}

window.addEventListener('resize', function(e) {
  if(openedVisualizer) visualizer_resize();
});
document.addEventListener('keydown', function(e) {
  if(openedVisualizer) {
    switch(e.which) {
      case 37: // left
        e.preventDefault();
        e.stopPropagation();
        visualizer_slide(openedVisualizer, -1);
        break;
      case 39: // right
        e.preventDefault();
        e.stopPropagation();
        visualizer_slide(openedVisualizer, 1);
        break;
      case 27: // esc
        e.preventDefault();
        e.stopPropagation();
        visualizer_close();
        break;
    }
  }
});

document.querySelectorAll('.visualizer').forEach(function(el) {
  visualizer_init(el);
});
