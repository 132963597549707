import { getOffset, getScrollTop } from "./utils.js";

var product_navbar = document.querySelector('#product-navbar');
var product_navbar_offset_top = 0;
var product_navbar_floating = false;
//scrollspy
var SCROLLSPY_OFFSET = 70;
var product_navbar_links = [];
var product_navbar_targets = [];
var product_navbar_target_offsets = [];
var product_navbar_active = -1;
var product_navbar_active_ignore = false;
function product_navbar_check() {
  var scrollTop = getScrollTop();
  if(scrollTop > product_navbar_offset_top) {
    if(!product_navbar_floating) {
      product_navbar.classList.add("floating");
      product_navbar_floating = true;
    }
  } else {
    if(product_navbar_floating) {
      product_navbar.classList.remove("floating");
      product_navbar_floating = false;
    }
  }
  //scrollspy
  if(product_navbar_active_ignore) return;
  var activeIndex = -1;
  for(var i = 0; i < product_navbar_target_offsets.length; i++) {
    if(product_navbar_target_offsets[i] == 999999) continue; //ignore null targets
    if(activeIndex >= 0 && product_navbar_target_offsets[activeIndex] > product_navbar_target_offsets[i]) continue; //ignore elements that are later in DOM but are already scrolled over
    if(product_navbar_target_offsets[i] <= scrollTop + SCROLLSPY_OFFSET) activeIndex = i;
    else break;
  }
  if(activeIndex != product_navbar_active) {
    product_navbar_links.forEach(function(a) { a.classList.remove('active') });
    if(activeIndex >= 0) product_navbar_links[activeIndex].classList.add('active');
    product_navbar_active = activeIndex;
  }
}

function product_navbar_refresh() {
  product_navbar.style.width = product_navbar.parentNode.offsetWidth + "px";
  product_navbar_offset_top = getOffset(product_navbar.parentNode).top;
  product_navbar_target_offsets = [];
  product_navbar_targets.forEach(function(el) {
    product_navbar_target_offsets.push(el ? getOffset(el).top : 999999);
  });
  product_navbar_check();
}

function product_navbar_clicked() {
  product_navbar_active_ignore = true;
  product_navbar_links.forEach(function(a) { a.classList.remove('active') });
  this.classList.add('active');
  setTimeout(function() {product_navbar_active_ignore = false;}, 250);
}

if(product_navbar) {
  product_navbar_links = product_navbar.querySelectorAll("a");
  product_navbar_links.forEach(function(a) {
    var href = a.getAttribute('href');
    product_navbar_targets.push(href == "#" ? null : document.querySelector(href));
    a.addEventListener('click', product_navbar_clicked);
  });
  window.addEventListener('scroll', product_navbar_check);
  window.addEventListener('resize', product_navbar_refresh);
  product_navbar_refresh();
}
