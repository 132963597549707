import Rails from "@rails/ujs";

function handleFavouriteIconClick(e) {
  e.preventDefault();
  Rails.fire(this.parentNode.querySelector('form'), 'submit');
  this.classList.add('active');
  this.querySelector('.icon-stack').innerHTML = '<i class="icon icon-spinner"></i>';
}

Rails.delegate(document, "a.favourite-icon", 'click', handleFavouriteIconClick);
