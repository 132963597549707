var blogmenu_offcanvas = document.getElementById("blogmenu-offcanvas");
if(blogmenu_offcanvas) {
  var original_color = document.querySelector("meta[name='theme-color']").getAttribute("content");
  blogmenu_offcanvas.addEventListener('show.bs.offcanvas', function(e) {
    document.querySelector("meta[name='theme-color']").setAttribute("content", "#ac8c4c");
  });
  blogmenu_offcanvas.addEventListener('hidden.bs.offcanvas', function(e) {
    document.querySelector("meta[name='theme-color']").setAttribute("content", original_color);
  });
}
