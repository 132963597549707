import { getWindowHeight, scrollTop, getOffset, closestWithClass } from "./utils.js";
import Rails from "@rails/ujs";

function handleAnchorLinkClick(e) {
  var href = this.getAttribute('href');
  if(href == "#") scrollTop(0);
  else {
    var target = document.querySelector(this.getAttribute('href'));
    if(target) {
      var offset = this.getAttribute("data-anchor-offset");
      var parentClass = this.getAttribute("data-anchor-in-class");
      if(parentClass) {
        var parent = closestWithClass(this, parentClass);
        var targetTop = getOffset(target).top - getOffset(parent).top + parent.scrollTop;
        parent.scrollTop = Math.max(0, offset ? targetTop - (parseInt(offset) || 0) : targetTop - parent.offsetHeight/5);
      } else {
        var windowHeight = getWindowHeight();
        var targetTop = getOffset(target).top;
        scrollTop(Math.max(0, offset ? targetTop - (parseInt(offset) || 0) : targetTop - windowHeight/5));
      }
    }
  }
  e.preventDefault();
}

Rails.delegate(document, "a.anchor-link", 'click', handleAnchorLinkClick);
