//https://github.com/WebReflection/dom4/blob/master/build/dom4.max.js

export function getWindowHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
}

export function getWindowWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0;
}

export function getScrollTop() {
  return window.pageYOffset;
}

export function scrollTop(top) {
  window.scrollTo(window.pageXOffset, top);
}

export function getOffset(element) {
  if (!element.getClientRects().length) {
    return { top: 0, left: 0 };
  }

  let rect = element.getBoundingClientRect();
  let win = element.ownerDocument.defaultView;
  return ({
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset
  });
}

export function reflow(element) { //trick to cause css animation to restart
  return element.offsetWidth;
}

export function closestWithClass(element, className) {
  while(element && element.classList && !element.classList.contains(className)) element = element.parentNode;
  return element.classList ? element : null;
}

export function htmlEscape(unsafe) {
  return unsafe
   .replace(/&/g, "&amp;")
   .replace(/</g, "&lt;")
   .replace(/>/g, "&gt;")
   .replace(/"/g, "&quot;")
   .replace(/'/g, "&#039;");
}

//feature detection
//https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#improving_scrolling_performance_with_passive_listeners
export var passiveIfSupported = false;
//https://stackoverflow.com/questions/64833727/when-i-touch-my-javascript-slider-on-mobile-i-cant-scroll-down-the-page
export var passiveFalseIfSupported = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, "passive", {
    get: function() {
      passiveIfSupported = {passive: true};
      passiveFalseIfSupported = {passive: false};
    }
  }));
} catch(err) {}
