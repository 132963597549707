import { getOffset, getScrollTop } from "./utils.js";

// used to keep documents navigation on screen
var sticky = document.getElementById("sticky");
if(sticky) {
  sticky.style.position = "relative";
  var sticky_frame = document.getElementById("sticky-frame") || sticky.parentNode;
  var sticky_frame_offset_top = 0, sticky_frame_height = 0, sticky_height = 0;
  function sticky_check() {
    var scrollTop = getScrollTop();
    var max_offset = sticky_frame_height - sticky_height;
    var offset = scrollTop - sticky_frame_offset_top + 10;
    if(offset < 0) offset = 0;
    else if(offset > max_offset) offset = max_offset;
    sticky.style.top = offset + "px";
  }
  function sticky_refresh() {
    sticky_frame_offset_top = getOffset(sticky_frame).top;
    sticky_frame_height = sticky_frame.offsetHeight;
    sticky_height = sticky.offsetHeight;
    sticky_check();
  }
  window.addEventListener('scroll', sticky_check);
  window.addEventListener('resize', sticky_refresh);
  sticky_refresh();
}
