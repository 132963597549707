import Rails from "@rails/ujs";

function handleLinkOutClick(e) {
  var url = this.getAttribute('data-safe-url');
  //window.location.href = url; // target attribute on <a> does not work
  //e.preventDefault();
  this.href = url;
}

Rails.delegate(document, "a[data-safe-url]", 'click', handleLinkOutClick);
