import rangeSlider from "./range-slider";
import { closestWithClass } from "./utils.js";
import { quick_view_init } from "./quick-view";
import Rails from "@rails/ujs";

// pagination
function products_paginable_loading() {
  document.getElementById('products_paginable_loading').classList.add('show');
  document.getElementById('products_loading_dialog').classList.add('show');
}
//products_paginable_loading();

function products_paginable_finished(scroll_up) {
  //document.getElementById('products_loading_dialog').classList.remove('show');
  quick_view_init(scroll_up);
}

export function products_paginable_load(url) { // export for quick view
  Rails.ajax({
    type: "GET",
    url: url,
    dataType: 'script',
    success: function(response, statusText, xhr) {
      products_paginable_finished(true);
    }
  });
  products_paginable_loading();
  if(history.replaceState) history.replaceState(null, document.title, url);
}

function handleProductsPaginableClick(e) {
  products_paginable_load(this.href);
  e.preventDefault();
}

var products_paginable = document.getElementById("products_paginable");
if(products_paginable) {
  Rails.delegate(products_paginable, ".nav-tabs a", 'click', handleProductsPaginableClick);
  Rails.delegate(products_paginable, ".pagination li:not(.disabled) a", 'click', handleProductsPaginableClick);
}


// filters
var filter_search_ignore = false;
var filter_search_timeout = null;
var filter_search_instance = null;
function send_filter_search() {
  if(filter_search_ignore) return;
  if(filter_search_timeout) {
    clearTimeout(filter_search_timeout);
    filter_search_timeout = null;
  }
  products_paginable_loading();
  filter_search_timeout = setTimeout(function() {
    var instance = new Date().getTime();
    filter_search_instance = instance;
    var form = document.getElementById('filter-searcher');
    Rails.ajax({
      type: "GET",
      url: form.getAttribute('action'),
      data: Rails.serializeElement(form),
      dataType: 'script',
      beforeSend: function(xhr, options) { //replace callback in order to stop processing of result when there is newer request already known
        var originalCallback = xhr.onreadystatechange;
        xhr.onreadystatechange = function() {
          if(filter_search_instance == instance) originalCallback();
        }
        return true;
      },
      success: function(response, statusText, xhr) {
        products_paginable_finished(false);
      }
    });
    if(history.replaceState) history.replaceState(null, document.title, form.getAttribute("action")+"?"+Rails.serializeElement(form));
    check_clear_button_and_fill_info();
  }, 150);
}
function check_clear_button_and_fill_info() {
  var any = false, info = "";
  document.querySelectorAll("#filter-searcher input[type=text]").forEach(function(input) {
    if(parseInt(input.value) != parseInt(input.getAttribute("data-default"))) any = true;
  });
  if(any) info = document.querySelector('.filter-slider-min').value + " – " + document.querySelector('.filter-slider-max').value;
  document.querySelectorAll("#filter-searcher input[type=checkbox]").forEach(function(checkbox) {
    if(checkbox.checked) {
      if(info != "") info += ", ";
      info += checkbox.parentNode.querySelector('.filter-title').innerText;
      any = true;
    }
  });
  document.querySelectorAll('.filter-searcher-clear').forEach(function(button) {
    any ? button.classList.remove('invisible') : button.classList.add('invisible');
  });
  document.querySelectorAll('.filter-by-info').forEach(function(el) {
    el.innerHTML = info;
  });
}

// filter clear button
document.querySelectorAll('.filter-searcher-clear').forEach(function(button) {
  button.addEventListener('click', function(e) {
    filter_search_ignore = true;
    document.querySelectorAll("#filter-searcher input[type=text]").forEach(function(input) {
      if(parseInt(input.value) != parseInt(input.getAttribute("data-default"))) {
        input.value = input.getAttribute("data-default");
        Rails.fire(input, 'blur');
      }
    });
    document.querySelectorAll("#filter-searcher input[type=checkbox]").forEach(function(checkbox) {
      checkbox.checked = false;
    });
    filter_search_ignore = false;
    send_filter_search();
  });
});

// filter checkboxes update
document.querySelectorAll('#filter-searcher input[type=checkbox]').forEach(function(checkbox) {
  checkbox.addEventListener('change', function(e) {
    send_filter_search();
  });
});

// range slider setup
function setupRangeSliderInput(rs, input, isMin) {
  input.setAttribute('data-last-value', parseInt(input.value));
  input.addEventListener('keypress', function(e) {
    if(event.keyCode === 13) {
      e.preventDefault();
      input.blur();
    }
  });
  input.addEventListener('blur', function(e) {
    var value = parseInt(input.value);
    if(isMin) {
      if(value < rs.min) value = rs.min;
      else if(value > rs.maxValue) value = rs.maxValue;
    } else {
      if(value < rs.minValue) value = rs.minValue;
      else if(value > rs.max) value = rs.max;
    }
    input.value = value + " " + input.getAttribute('data-units');
    var lastValue = parseInt(input.getAttribute("data-last-value"));
    if(value != lastValue) {
      input.setAttribute("data-last-value", value);
      isMin ? rs.setMinValue(value) : rs.setMaxValue(value);
    }
  });
}
document.querySelectorAll('.range-slider').forEach(function(slider) {
  var rs = new rangeSlider(slider);
  var low = slider.parentNode.querySelector(".filter-slider-min"), high = slider.parentNode.querySelector(".filter-slider-max");
  rs.onChange = function(min, max) {
    var units = low.getAttribute("data-units");
    low.value = min + (units != "" ? " "+units : "");
    low.setAttribute('data-last-value', min);
    high.value = max + (units != "" ? " "+units : "");
    high.setAttribute('data-last-value', max);
  };
  rs.didChanged = function(min, max) {
    send_filter_search();
  };
  setupRangeSliderInput(rs, low, true);
  setupRangeSliderInput(rs, high, false);
});

// inputs with units
document.querySelectorAll('input[data-units]').forEach(function(input) {
  input.value = input.value + " " + input.getAttribute('data-units');
  input.addEventListener('focus', function(e) {
    input.value = parseInt(input.value);
  });
  /* this is done in slider setup, as it check validitity too
  input.addEventListener('blur', function(e) {
    input.value = input.value + " " + input.getAttribute('data-units');
  });
  */
});

// filter-box headers
/* done with bs collapse
document.querySelectorAll('.filter-box-header a').forEach(function(link) {
  link.addEventListener('click', function(e) {
    e.preventDefault();
    closestWithClass(link, 'filter-box').classList.toggle('closed');
  });
});
*/
// filter box more options
document.querySelectorAll('.filter-show-more-options a').forEach(function(link) {
  link.addEventListener('click', function(e) {
    e.preventDefault();
    /* done with bs collapse
    closestWithClass(link, 'filter').querySelectorAll('li.hidden').forEach(function(li) {
      li.classList.remove('hidden');
    });
    */
    link.parentNode.classList.add('hidden');
  });
});
