import { htmlEscape, passiveIfSupported } from "./utils.js";
import Rails from "@rails/ujs";

var SPINNER_HTML = '<i class="icon icon-spinner"></i>';

var quick_search_timeout = null;
var quick_search_instance = null;
var last_search_term = "";
function send_quick_search() {
  var search_term = document.getElementById("quick-search-input").value;
  if(last_search_term == search_term) return;
  last_search_term = search_term;

  if(quick_search_timeout) {
    clearTimeout(quick_search_timeout);
    quick_search_timeout = null;
  }
  document.getElementById("quick-search-status").innerHTML = SPINNER_HTML + " Hledám "+htmlEscape(search_term)+"&hellip;";
  quick_search_timeout = setTimeout(function() {
    var instance = new Date().getTime();
    quick_search_instance = instance;
    var form = document.getElementById("quick-searcher");
    Rails.ajax({
      type: "GET",
      url: form.getAttribute('action'),
      data: "source=quick-searcher&" + Rails.serializeElement(form),
      dataType: 'script',
      beforeSend: function(xhr, options) { //replace callback in order to stop processing of result when there is newer request already known
        var originalCallback = xhr.onreadystatechange;
        xhr.onreadystatechange = function() {
          if(quick_search_instance == instance) originalCallback();
        }
        return true;
      }
    });
  }, 250);
}

function quickSearchKeup(event) {
  if(event.keyCode == 38 || event.keyCode == 40) {
    var all = document.querySelectorAll("#q-s-r-products a");
    if(all.length <= 0) return;
    var current = document.querySelector("#q-s-r-products a.active");
    var next = event.keyCode == 40 ? all[0] : all[all.length-1];
    if(current) {
      current.classList.remove("active");
      var currentIndex = Array.prototype.indexOf.call(all, current);
      next = event.keyCode == 40 ? all[currentIndex == all.length-1 ? 0 : currentIndex+1] : all[currentIndex == 0 ? all.length-1 : currentIndex-1];
    }
    next.classList.add("active");
  } else
  if(event.keyCode == 8 || event.keyCode > 32 && String.fromCharCode(event.keyCode).match(/^\S+$/i)) send_quick_search();
}

var results_timeout = null;
function quickSearchFocus(e) {
  if(results_timeout) {
    clearTimeout(results_timeout);
    results_timeout = null;
  }
  document.getElementById("quick-search-results").classList.add("show");
  send_quick_search();
}
function quickSearchBlur(e) {
  results_timeout = setTimeout(function() {
    document.getElementById("quick-search-results").classList.remove("show");
  }, 100);
}

function searchOffcanvasTouchStart(e) {
  var quick_search_input = document.getElementById("quick-search-input");
  if(e.target === quick_search_input) return;
  if(document.activeElement === quick_search_input) {
    quick_search_input.setAttribute('readonly', 'readonly'); // Force keyboard to hide on input field.
    setTimeout(function() {
        quick_search_input.blur();  //actually close the keyboard
        quick_search_input.removeAttribute('readonly');
    }, 100);
  }
}

var quick_searcher = document.getElementById("quick-searcher"); //form
if(quick_searcher) {
  document.getElementById('search-offcanvas').addEventListener('shown.bs.offcanvas', function(e) {
    document.getElementById('quick-search-input').focus();
  });

  Rails.delegate(quick_searcher, "#q-s-r-products a", 'mouseover', function(e) { // deactivate any activated element when mouse is used
    document.querySelectorAll("#q-s-r-products a.active").forEach(function(a) {a.classList.remove("active")});
  });

  Rails.delegate(quick_searcher, ".qs-term", "click", function(e) {
    var input = document.getElementById("quick-search-input");
    input.value = this.innerText;
    input.focus();
    send_quick_search();
    e.preventDefault();
  });

  quick_searcher.addEventListener("submit", function(e) {
    var item = document.querySelector('#q-s-r-products a.active');
    if(item) {
      e.preventDefault();
      window.location.href = item.href;
    }
  });

  var quick_search_input = document.getElementById("quick-search-input");
  quick_search_input.addEventListener("keyup", quickSearchKeup);
  quick_search_input.addEventListener("focus", quickSearchFocus);
  quick_search_input.addEventListener("blur", quickSearchBlur);
  //on mobile opened OSK breaks scrolling, result scroll first and when on end the whole page(offcanvas) scrolls :(
  document.getElementById('search-offcanvas').addEventListener('touchstart', searchOffcanvasTouchStart, passiveIfSupported);
}
